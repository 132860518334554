function contactComponent() {
    if ($('#contact-form').length > 0) {
        loadCaptcha('#contact-form .g-recaptcha');
    }
}

function contactFormRecaptcha() {
    $('#contact-form [type="submit"]').prop("disabled", false);
}

function expiredContactFormCaptcha() {
    $('#contact-form [type="submit"]').prop("disabled", true);
}

function resetCaptcha() {
    grecaptcha.reset();
}